import PropTypes from "prop-types"
import React, { useContext } from "react";
import AppContext from "@src/context";
import Modal from "./Modal.jsx";

const ContentModal = ({children}) => {
	const ctx = useContext(AppContext);

	const closeModal = () => {
		ctx.setModalOpen(false);
		ctx.setExternalUrl(null);
		ctx.setModalType(null);
	};

	return (
		<Modal addedClass="modal_form" closeModalFn={closeModal}>
			{children}
			<button
				className="close_modal_btn"
				data-close-modal
				aria-label="Close Modal"
				onClick={closeModal}
			/>
		</Modal>
	);
};

ContentModal.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ContentModal;
