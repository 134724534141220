import React, { useState, useEffect, useContext } from 'react';
import AppContext from "@src/context";
import axios from 'axios';
import { KfButton } from '@klickinc/kf-react-components';
import { useStaticQuery, graphql } from 'gatsby';
import ContentModal from '@components/structure/modal/Modal.Content.jsx'
import GTM from '@utils/GTM';

// Validate form
import validate from '@klickinc/util-validator';

// style imports
import '../../../pages/sign-up-for-updates/styles.scss';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const SignupForm = () => {
	const { site } = useStaticQuery(query);
	const { apiUrl } = site.siteMetadata;
	const [loading, setLoading] = useState(false);
	const ctx = useContext(AppContext);

	const resetFields = () => {
		const fields = document.querySelectorAll('.field');
		fields.forEach(field => {
			field.classList.remove('error');
		});
	};

	const setYear = () => {
		let currentYear = new Date().getFullYear();
		let year = 1990;
		let yearMap = [];
		for (let i = 1; i <= currentYear - 1989; i++) {
			yearMap.push({ 'label': year, 'val': year.toString() });
			year++;
		}
		yearMap.push({ 'label': "Please select a year", 'val': null });
		yearMap.reverse();
		return yearMap;
	};

	const openModal = (e) => {
		e.preventDefault();
		ctx.setModalOpen(true);
		ctx.setModalType('content');
	};

	const handleErrors = (errors) => {
		let anyErrors = false;

		for (let error in errors) {
			const input = document.querySelector(`#${error}`);
			anyErrors = true;
			if (input) {
				const field = input.closest('.field');
				if (field) {
					field.classList.add('error');
					const fieldError = field.querySelector('.field-error');
					if (fieldError) {
						GTM.fieldError(`${input.name}/${fieldError.innerText}`);
					}
				}
			}
		}

		if (anyErrors) {
			setLoading(false);
		}

		return anyErrors;
	}

	const submitHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		resetFields();
		const firstName = document.querySelector('#firstName').value;
		const lastName = document.querySelector('#lastName').value;
		const email = document.querySelector('#email').value;
		const zipCode = document.querySelector('#zipCode').value;
		const childBirthYear = document.querySelector('#childBirthYear').value;
		const consent = document.querySelector('#consent').checked;

		const ruleSet = {
			subscribe: {
				rules: [
					{ name: 'required', fields: ['firstName', 'lastName', 'email', 'zipCode', 'childBirthYear', 'consent'], required: true },
					{ name: 'name valid', fields: ['firstName', 'lastName'], validators: [/^.{1,50}$/] },
					{ name: 'email valid', fields: ['email'], validators: [/^(?=.{1,100}$)(([0-9a-zA-Z])+([-._'+&]))*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}$/]},
					{ name: 'zip valid', fields: ['zipCode'], validators: [/^[0-9]{5}$/] },
					{ name: 'childBirthYear valid', fields: ['childBirthYear'], validators: [(value, field, data, rule) => value >= '1990' && value <= new Date().getFullYear()] },
					{ name: 'consent valid', fields: ['consent'], validators: [[true]] },
				],
			},
		};

		const errors = validate(ruleSet.subscribe.rules,
			{
				firstName: firstName,
				lastName: lastName,
				email: email,
				zipCode: zipCode,
				childBirthYear: childBirthYear,
				consent: consent,
			}
		);

		if (handleErrors(errors)) {
			return
		}

		try {
			const response = await axios.post(
				`${apiUrl}/subscribe`,
				{
					firstName,
					lastName,
					email,
					zipCode,
					childBirthYear,
					consent
				},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			);
			if (response && response.data) {
				if (response.data.success && Object.keys(response.data.errors).length === 0) {
					window.location.href = '/sign-up-for-updates/confirmation';
					GTM.formCompletion();
				} else {
					const errors = response.data.errors['dataservice-httpjson-validate'];
					handleErrors(errors)
				}
			}
		} catch (err) {
			window.location.href = '/sign-up-error';
		}
	};

	const handleChange = (name) => {
		const input = document.querySelector(`#${name}`);
		GTM.fieldCompletion(input.name)
	};


	useEffect(() => {
		if (typeof window !== 'undefined') {
			const inputs = document.querySelectorAll('input, select');
			inputs.forEach(input => {
				input.addEventListener('change', () => handleChange(input.name));
			});
		}
		return () => {
			const inputs = document.querySelectorAll('input, select');
			inputs.forEach(input => {
				input.removeEventListener('change', () => handleChange(input.name));
			});
		};
	},[]);

	return (
		<>
			<form className="pb-28 md:pb-28 md:max-w-[720px] md:mr-isi">
				<div className="md:flex md:justify-between">
					<div className="mb-4 field md:mr-10 md:flex-1">
						<div className="kf-textInput">
							<label htmlFor="firstName" className="kf-textInput__label">
								First Name
							</label>
							<input
								className="kf-textInput__field"
								type="text"
								name="firstName"
								id="firstName"
								aria-labelledby="firstName_error"
							/>
						</div>
						<div id="firstName_error" className="field-error">
							Please enter a valid first name
						</div>
					</div>
					<div className="mb-4 field md:flex-1">
						<div className="kf-textInput">
							<label htmlFor="lastName" className="kf-textInput__label">
								Last Name
							</label>
							<input
								className="kf-textInput__field"
								type="text"
								name="lastName"
								id="lastName"
								aria-labelledby="lastName_error"
							/>
						</div>
						<div id="lastName_error" className="field-error">
							Please enter a valid last name
						</div>
					</div>
				</div>
				<div className="flex-col md:flex">
					<div className="mb-6 field xs:w-[319px] md:w-[340px]">
						<div className="kf-textInput">
							<label htmlFor="email" className="kf-textInput__label">
								Your Email Address
							</label>
							<input
								className="kf-textInput__field"
								type="text"
								name="email"
								id="email"
								aria-labelledby="email_error"
							/>
						</div>
						<div id="email_error" className="field-error">
							Please enter a valid email address
						</div>
					</div>
					<div className="mb-6 field xs:w-[319px] md:w-[340px]">
						<div className="kf-textInput">
							<label htmlFor="zipCode" className="kf-textInput__label">
								ZIP Code
							</label>
							<input
								type="text"
								className="kf-textInput__field"
								name="zipCode"
								id="zipCode"
								aria-labelledby="zip_error"
							/>
						</div>
						<div id="zip_error" className="field-error">
							Please enter a valid ZIP code
						</div>
					</div>
					<div className="mb-16 md:mb-28 field xs:w-[319px] md:w-[340px]">
						<div className="kf-selectInput ">
							<label htmlFor="childBirthYear">
								Child's Year of Birth
							</label>
							<select name="childBirthYear" id="childBirthYear" aria-labelledby="year_error">
								{setYear().map((opt) => (
									<option key={opt.val} value={opt.val}>
										{opt.label}
									</option>
								))}
							</select>
						</div>
						<div id="year_error" className="field-error">
							Please select a valid birth year
						</div>
					</div>
				</div>
				<div className="submitCheckbox field md:justify-between md:w-[380px]">
					<div className="flex space-x-3">
						<input
							type="checkbox"
							name="consent"
							id="consent"
							className="mt-1 h-5 w-5 min-w-[18px]"
						></input>
						<div>
							<label
								htmlFor="consent"
								className="cursor-pointer xs:max-w-[280px] md:max-w-[350px]"
							>
								By checking this box, I attest to the fact that
								I am 18&nbsp;years of age or older and have
								read, understand, and agree to all the <span className='sr-only'><em>Terms and Conditions</em></span>
							</label>
							<button
								onClick={openModal}
								className="kf-anchorlink underline hover:cursor-pointer focus:cursor-pointer"
							>
								<strong>Terms and Conditions</strong>
							</button>
							.
						</div>
					</div>
					<div className="field-error ml-4">
						Please check the box to proceed
					</div>
				</div>
				<div className="mt-4 text-center md:text-left">
					<KfButton
						addedClass="btn submitBtn xs:w-72 md:w-60"
						type="submit"
						clickFn={(e) => submitHandler(e)}
					>
						{loading ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								xlinkHref="http://www.w3.org/1999/xlink"
								style={{
									margin: "auto",
									height: "24px",
									verticalAlign: "middle",
									display: "inline-block",
									shapeRendering: "auto",
								}}
								width="200px"
								height="200px"
								viewBox="0 0 100 100"
								preserveAspectRatio="xMidYMid"
							>
								<circle
									cx="50"
									cy="50"
									fill="none"
									stroke="#1f3e5c"
									strokeWidth="10"
									r="35"
									strokeDasharray="164.93361431346415 56.97787143782138"
								>
									<animateTransform
										attributeName="transform"
										type="rotate"
										repeatCount="indefinite"
										dur="1s"
										values="0 50 50;360 50 50"
										keyTimes="0;1"
									></animateTransform>
								</circle>
							</svg>
						) : (
							<span>Submit</span>
						)}
					</KfButton>
				</div>
			</form>
			{/* If there's an exit modal to be within ContentModal and we need to show it over Content one(currently it'd close Content one and show the Exit one), code would need some minor refactoring (add another state "modalOpenExit" for ExitModal etc.) */}
			{ctx.modalOpen && ctx.modalType === "content" && (
				<ContentModal>
					<div>
						<p className="orange_subHeader modal sm:w-72 md:w-[35rem]">
							Consent to use & disclose patient caregiver
							information
						</p>
						<p>
							The personal information I have provided on this
							form is complete and accurate to the best of my
							knowledge.
						</p>
						<p>
							I have voluntarily chosen to provide this personal
							information, including contact information. By
							providing this information, I am authorizing PTC
							Therapeutics, Inc. and its agents and contractors
							(“PTC Therapeutics”) to contact me about treatments,
							products, and services related to the treatment of
							Duchenne muscular dystrophy (DMD), as well as to use
							and disclose the information for other purposes
							described below, using the contact information I
							have provided. I understand and agree that PTC
							Therapeutics may leave messages for me that disclose
							that someone has DMD or takes specific medication or
							that a specific medication used to treat DMD is
							available.
						</p>
						<p>I authorize PTC Therapeutics to:</p>
						<ul className="xs:pl-8 md:pl-14">
							<li className="mt-3">
								Send me information or materials related to
								EMFLAZA<sup>&reg;</sup> (deflazacort) or any
								other treatments, products, or services related
								to the treatment of DMD
							</li>
							<li className="mt-3">
								Have a healthcare provider contact me by phone
								(or other preferred means of communication) to
								discuss the current treatments and treatment
								options for the person for whom I provide care
							</li>
							<li className="mt-3">
								Use the information I have provided for the
								provision of education, training, and ongoing
								support of the use of EMFLAZA
							</li>
							<li className="mt-3">
								Contact me to obtain my feedback (for market
								research or other purposes) about EMFLAZA or any
								of the related services offered by PTC
								Therapeutics, including the support services
								provided by the{" "}
								<br className="block sm:hidden" /> PTC{" "}
								<span className="italic">Cares</span>
								<span>&trade;</span> Patient Services Center
							</li>
							<li className="mt-3">
								Use the information I have provided for market
								research or other data purposes, and contact me
								as otherwise required or permitted by law
							</li>
						</ul>
						<p className="mt-4">
							If at any time I do not wish to be contacted by PTC
							Therapeutics about or to receive any information
							related to EMFLAZA or any other treatment, product,
							or service related to the treatment of DMD or to be
							contacted for any of the other purposes described
							above, I understand that I may unsubscribe at any
							time.
						</p>
					</div>
				</ContentModal>
			)}
		</>
	);
};

const query = graphql`
	query TelemedicineResources {
		site {
			siteMetadata {
				apiUrl
			}
		}
	}
`;

export default SignupForm;
